@font-face {
    font-family: 'Gilroy-Medium';
    src: url('../fonts/gilroy-medium.eot'); /* IE 9 Compatibility Mode */
    src: url('../fonts/gilroy-medium.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('../fonts/gilroy-medium.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/gilroy-medium.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../fonts/gilroy-medium.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/gilroy-medium.svg#gilroy-medium') format('svg'); /* Chrome < 4, Legacy iOS */
}
body {
    font-size: 14px;
    font-family: 'Gilroy-Medium';
}
.login-box, .register-box {
    width: 360px;
    margin: 10% auto;
}
.login_failure_text {
    margin-top: 35px;
}
.text_some_another {
    font-size: 19px;
}
.orders-table-main {
    min-height: 500px;
}
h3 {
    color: #262555!important;
    font-size: 34px!important;
    font-weight: 600!important;
    padding: 20px 0px;
}
.wrapper {
    margin-bottom: 50px;
}
.orange_button {
    padding: 10px;
    background-color: #D39340;
    color: #262555;
    /*font-weight: 600;*/
}
.orange_button:hover {
    color: white;
}
.blue_button {
    padding: 10px;
    background-color: #262555;
    color: #D39340;
}
.blue_button:hover {
    color: white;
}
.slick-dots li button:before {
    color: #d39340!important;
    font-size: 13px!important;
}
.slick-dots li.slick-active button:before {
    color: #d39340!important;
}
.info_page_title {
    color: #262555;
    font-size: 24px;
    font-weight: 600;
    padding: 10px 0px;
}
.info_page_link {
    color: #d39340!important;
}
button {
    outline: none!important;
}
.home_main_slider_section .slick-slide img {
    width: 100%;
}
.partner_alert a {
    color: #d39340!important;
}
blockquote {
    border-left: .7rem solid #d39340!important;
}
/*моб версія*/
@media (max-width: 321px) {
    /*хеадер*/
    .header_contact_info {
        font-size: 9px!important;
    }
    .products_cart_options_item_value {
        word-break: break-word;
    }
    .products_card_price_inner .products_card_price_all {
        font-size: 21px!important;
    }
    .products_card_price_inner .products_card_price_for_one {
        font-size: 9px!important;
    }
    .products_card_buttons_container .btn {
        width: 143px!important;
        font-size: 13px!important;
    }
    .tags_main_container .tags_item {
        width: 46% !important;
    }

}
@media (min-width: 769px) and (max-width: 1024px) {
    .header_center_logo {
        width: 50% !important;
    }
    .header_center {
        width: 50% !important;
        padding-right: 0px!important;
    }
    .header_center_contact {
        width: 100%!important;
        padding: 0% 20%;
        margin-top: 15px;
    }
}
@media (min-width: 425px) and (max-width: 768px) {
    .products_gallery_main_title h3 {
        line-height: 34px!important;
    }
    .header_center_logo {
        width: 100% !important;
        justify-content: center;
    }
    .header_center {
        width: 100% !important;
    }
    .header_center_contact {
        width: 100%!important;
        padding: 0% 10%;
        margin-top: 10px;
    }
    .product_gallery_button button {
        font-size: 22px;
        padding: 14px;
    }
    .products_filter_item_title {
        font-size: 24px!important;
    }
    /*.products_filter_main_container.mobile_style_filters {*/
    /*    width: 500px;*/
    /*}*/
    .filter_price_checkbox.custom-control.custom-checkbox.mr-sm-2 label {
        font-size: 20px!important;
    }
    .products_category_main_container .row .col-md-3 {
        z-index: 999;
        min-width: 100%;
    }
    .products_category_main_container .row .col-md-9 {
        z-index: 999;
        min-width: 100%;
    }
    .products_filter_main_container {
        margin-bottom: 15px;
    }
    .products_filter_main_container .products_filter_title {
        font-size: 24px;
        margin-bottom: 0px;
    }
    .products_filter_item_body.price {
        margin-top: 20px;
    }
    .breadcrumb-item > a {
        font-size: 19px;
    }
}
@media (max-width: 425px) {
    .products_card_tabs_container .nav-link {
        max-width: 100%;
    }
    .registration_sub_title {
        max-width: 50% !important;
    }
    .register_form .form-group {
        flex-flow: column!important;
        align-items: flex-start!important;
    }
    .account_order_invoice_link img {
        display: none;
    }
    .account_content_container .card:nth-child(1) {
        height: 400px;
        width: 100%;
        overflow: scroll;
    }
    .account_content_container .card table {
        font-size: 12px;
    }
    .partner_add_product_sale_container .form-select-container {
        max-width: 90%!important;
    }
    .header_search {
        width: 84% !important;
    }
    .products_filter_main_container.mobile_style_filters {
        width: 100%;
    }
    header .wrapper {
        display: flex;
        flex-flow: column;
    }
    .products_card_image_container .css-12pjucg {
        max-height: 250px;
    }
    .products_card_image_container .css-1hew7ia {
        object-fit: contain;
    }
    .products_card_in_stock {
        margin-bottom: 15px;
        margin-top: 15px;
    }
    .products_cart_options_item_value {
        word-break: break-word;
    }
    /*хеадер медіа запрос під моб версію*/
    .main_menu_container {
        flex-flow: column!important;
    }
    .menu_item.menu_pages {
        flex-flow: column;
    }
    .menu_item.menu_categories {
        margin-right: 0px!important;
        padding-bottom: 5px;
    }
    .header_center_logo {
        justify-content: center;
        margin-bottom: 10px;
        width: 100%!important;
        padding: 0% 2%;
    }
    .header_center {
        justify-content: center;
    }
    .header_center_contact {
        font-size: 14px;
        width: 100%!important;
        justify-content: space-around!important;
        padding: 0% 2%;
    }
    /*головна сторінка*/
    .home_main_slider_section {
        display: none;
    }
    /*Корзина*/
    .cart_title_type {
        width: 100% !important;
    }
    .cart_name_product {
        text-align: center;
    }
    .cart_info_product {
        padding-top: 10px;
        justify-content: initial!important;
    }
    .cart_delete_product {
        position: absolute;
    }
    /*футер*/
    footer {
        text-align: center;
    }
    /*інфо сторінки*/
    .products_gallery_main_title h3 {
        line-height: 1;
    }
    /*картка товару*/
    .products_card_datas_container {
        padding-left: 0px!important;
    }
    .products_cart_options_item_title {
        width: 200px!important;
    }
    .products_card_price_container {
        justify-content: space-between;
    }
    .products_filter_main_container {
        padding: 10px;
        margin-bottom: 20px;
    }
    .products_filter_title {
        margin-bottom: 0px!important;
        font-size: 18px!important;
    }
    .account_user_photo img {
        max-width: 50px!important;
    }
    .account_user_photo {
        width: 100px!important;
    }
    .account_mobile_menu {
        padding: 10px;
        font-size: 18px;
        margin-top: 10px;
        width: 100%;
        display: block;
    }
    .cart_delete_product {
        position: absolute!important;
        right: 20px!important;
        padding-top: 8% !important;
        width: 35px!important;
    }
    .confirm_title_type h4 {
        max-width: 50% !important;
    }
    .confirm_title h3 {
        padding-bottom: 15px!important;
        line-height: 1!important;
    }
    .confirm_container .confirm_name_product {
        min-width: 100%;
        text-align: center;
        justify-content: center;
        margin-bottom: 10px;
    }
    .confirm_container .confirm_info_product {
        justify-content: inherit!important;
    }
    .confirm_container .cart_delete_product {
        padding-top: 10% !important;
    }
    .confirm_final_price {
        flex-wrap: nowrap!important;
    }
    .checkout .input-group.col-md-6 {
        margin-bottom: 10px!important;
    }
    .checkout .input-group.col-md-4 {
        margin-bottom: 10px!important;
    }
    .checkout .input-group.col-md-6:last-child {
        margin-bottom: 0px!important;
    }
    .checkout_button.col-md-2 {
        display: flex;
        justify-content: end;
    }
    .products_card_info_header {
        flex-flow: wrap!important;
    }
    .tags_item {
        width: auto!important;
        margin-right: 10px!important;
        padding: 0px 10px;
    }
    .tags_main_container {
        width: 100%;
    }
    .products_gallery_main_title {
        margin-top: 15px!important;
    }
    .products_gallery_main_title h3 {
        padding-bottom: 20px!important;
        margin-bottom: 20px!important;
    }
    .product_gallery_name a {
        font-size: 22px;
    }
    .product_gallery_name {
        margin-top: 5px;
    }
    .products_gallery_main_title h3 {
        line-height: 1!important;
    }
    .products_card_tabs_container .nav-tabs .nav-item {
        max-width: 50%;
    }
    .products_card_price_all {
        font-size: 26px!important;
    }
    .products_card_price_for_one {
        font-size: 11px!important;
    }
}