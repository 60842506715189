/*nav {*/
/*    background-color: #262555;*/
/*    padding: 35px 0 30px 0;*/
/*}*/
nav {
    background-color: #262555;
    padding: 20px 0 20px 0;
}
.main_menu_container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}
.menu_item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.menu_item_childrens_1 {
    position: absolute;
    left: 0;
    top: calc(100% + 15px);
    display: none;
    flex-flow: column;
    justify-content: flex-start;
    background-color: #ffffff;
    min-width: 250px;
    z-index: 15;
    border: 1px solid #262555;
}
.menu_item_childrens_2 {
    position: absolute;
    left: 100%;
    top: -1px;
    display: none;
    flex-flow: column;
    justify-content: flex-start;
    background-color: #ffffff;
    min-width: 250px;
    z-index: 5;
    border: 1px solid #262555;
    /*height: calc(100% + 1px);*/
    min-height: 100%;
}
.menu_item_title {
    font-style: normal;
    /*font-weight: bold;*/
    font-size: 25px;
    line-height: 24px;
    color: #FFFDFB;
}
.main_menu_container_mobile .menu_item_title {
    color: #262555;
}

.mbu_menu_container {
    background-color: #FFFFFF;
    padding: 15px;
    width: 250px;
    border: 1px solid #262555;
    z-index: 999;
}
.mbu_menu_container:not(:last-child) {
    border-bottom: none;
}
.mbu_menu_links {
    display: none;
    position: absolute;
    top: 43px;
    left: 0;
    flex-flow: column;

}
.mbu_menu_link_container {
    position: relative;
    flex-flow: column;
    height: 100%;
    top: -1px;
}
.mbu_menu_link_container > .mbu_menu_link_childrens {
    display: none;
    flex-flow: column;
}
.mbu_menu_link_container:hover > .mbu_menu_link_childrens {
    display: flex;
    position: absolute;
    left: 100%;
    top: 0;
}
.menu_item_title.active + .mbu_menu_links {
    display: flex;
}
.mbu_menu_link_container:hover > .mbu_menu_link > a {
    color: white!important;
}
.mbu_menu_link_container > .mbu_menu_link > a {
    color: #262555;
}
.mbu_menu_link_container:hover {
    background-color: #D39340;
}
.menu_item_mains_categories_container {
    display: none;
}
.menu_item_title.active + .menu_item_mains_categories_container {
    display: flex;
}
.menu_item_mains_categories_container > .menu_item_childrens_main {
    display: flex;
}
.menu_item_childrens_main:hover .menu_item_childrens_1 {
    display: flex;
}
.menu_item_children:hover > .menu_item_childrens_2 {
    display: flex;
}
.menu_item_children {
    padding: 10px 15px;
    position: relative;
}
.menu_item_children:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: lightgrey;
}
.menu_item_children:hover:after {
    background-color: #262555;
}
.menu_item_children:last-child:after {
    display: none;
}
.menu_item_children_title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #262555;
}
.menu_item.menu_pages a {
    padding-bottom: 5px;
    border-bottom: 1px solid transparent;
}
.menu_item.menu_pages a:hover {
    color: #D39340;
    border-bottom: 1px solid #D39340;
}
.menu_item.menu_pages {
    display: flex;
    justify-content: space-between;
    width: 100%;

}
/*.menu_item.menu_categories {*/
/*    margin-right: 8%;*/
/*}*/
.menu_item_children a {
    color: #262555;
    font-size: 18px;
}
.menu_item_children:hover {
    background-color: #D39340;
}
.menu_item_children:hover .childrens_1 > a {
    color: white;
}
.menu_item_childrens_2 .menu_item_children:hover > .menu_item_children_title > a {
    color: white;
}
.menu_item.menu_categories {
    cursor: pointer;
    padding-bottom: 5px;
}
.menu_item_childrens_1  > .menu_item_childrens_1  {
    left: 248px;
    top: -1px;
}

.main_menu_container_mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 9999;
    background-color: #ffffff;
    max-width: 768px!important;
}
.main_menu_container_mobile .row {
    flex-wrap: nowrap;
}
.main_menu_container_mobile .row .col-md-6 {
    padding: 20px;
    border: 1px solid lightgrey;
    border-radius: 50px;
    margin: 10px;
}
.mobile_menu_container {
    padding: 20px 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.mobile_menu_container .header_search input {
    box-shadow: none!important;
}
.main_menu_container_mobile_close {
    padding: 10px;
    text-align: right;
}
.main_menu_container_mobile_close span {
    font-size: 36px;
    color: #D39340;
    box-shadow: 0 0 15px 0px #5c5c5c;
    border-radius: 59px;
    height: 37px;
    width: 37px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.main_menu_container_mobile .row {
    flex-wrap: wrap;
}
.row_wrap {
    flex-wrap: wrap;
}
.color_change_menu_category a {
    color: #D39340;
    font-size: 22px;
}
.category_childs_true {
    color: #D39340;
    font-size: 22px;
}
.price_list_in_menu {
    background: none;
    border: none;
    border-bottom: 1px solid transparent;
    padding-bottom: 5px;
}
.price_list_in_menu:hover {
    color: #D39340;
    border-bottom: 1px solid #D39340;
}
.main_menu_text_warning {
    text-align: center;
    color: white;
    font-size: 18px;
    border-top: 2px solid #D39340;
    width: 100%;
    margin-top: 10px;
    padding-top: 5px;
}