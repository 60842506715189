header {
    padding: 20px 0px;
}
header .wrapper {
    margin-bottom: 0px;
}
.header_logo {
    width: 190px;
}
.header_language_button a {
    color: #262555;
    font-size: 18px;
}
.header_language_button {
    padding-bottom: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lang_active {
    color: #D39340!important;
}
.header_search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
}
.header_search input {
    border: none;
    box-shadow: 0 3px 5px lightgrey;
}
.header_search a {
    border: none;
    background-color: #262555;
    padding: 7px 10px 7px 10px;
    transition-duration: 200ms;
    transition-delay: 1ms;
    transition-timing-function: linear;
    transition-property: all;
    position: relative;
    overflow: hidden;
}
.header_search a:before {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100%;
    background: orange;
    transition-duration: 300ms;
    transition-delay: 1ms;
    transition-timing-function: linear;
    transition-property: all;
    z-index: 5;
}
.header_search a:hover:before {
    left: 0;
}
.header_search a img {
    position: relative;
    z-index: 9;
}
.header_contact_info {
    font-size: 12px;
    color: #7E848E;
    text-align: right;
    min-height: 18px;
}
.header_contact_main img {
    margin-right: 8px;
}
.header_contact_main a {
    color: #262555;
    font-weight: 600;
}
.header_contact_main {
    display: flex;
    align-items: center;
    color: #262555;
    font-weight: 600;
}
.header_center_logo div:first-child {
    margin-right: 10px;
}
.header_cart_image_container {
    position: relative;
}
.header_center_logo {
    display: flex;
    align-items: center;
    width: 26%;
}
.header_center {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 3%;
    width: 32%;
}
.header_center_contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 41%;
}
.header_cart_image_container.with_products:after {
    content: '';
    width: 9px;
    height: 9px;
    position: absolute;
    top: 0;
    right: 6px;
    background-color: rgb(211, 147, 64);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
.header_language_button a:first-child {
    padding-right: 3px;
    padding-left: 0px;
}
.header_language_button a {
    padding-right: 3px;
    padding-left: 3px;
}
.header_language_button a:last-child {
    padding-right: 0px;
    padding-left: 3px;
}
.header-top-bar {
    padding: 15px 10%;
    background-color: rgb(211, 147, 64);
    color: white;
    text-align: center;
    font-size: 13px;
}
