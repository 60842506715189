.products_filter_title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #7E848E;
    margin-bottom: 14px;
    display: block;
}
.products_filter_main_container {
    padding: 25px;
    background: #FFFFFF;
}
.products_filter_item_title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #262555;
    margin-bottom: 23px;
}
.products_filter_item_title.curet {
    cursor: pointer;
}
.filter_price_inputs {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}
.products_filter_item_input_container input {
    width: 91px;
    height: 36px;
    border: 1px solid rgba(38, 37, 85, 0.3)!important;
    box-sizing: border-box;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #262555;
    padding: 9px;
}
.products_filter_item_separator:before {
    content: '';
    display: block;
    width: 19px;
    height: 1px;
    background-color: #262555;
    margin: 0 9px;
}
.filter_price_inputs {
    margin-bottom: 23px;
}
.input-range__label-container {
    display: none;
}
.filters_price_selector {
    margin-bottom: 19px;
}
.filter_price_checkbox {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}
.custom-checkbox .custom-control-label::before {
    border-radius: 0;
}
.custom-control-label::before {
    background-color: #FFFFFF;
}
.custom-control-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #262555;
}
.products_filter_item_body  {
    margin-bottom: 29px;
}
.products_category_main_title h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 37px;
    color: #262555;
}
.products_category_main_title_count {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #7E848E;
}
.products_category_main_title {
    padding-left: 7.5px;
    margin-bottom: 31px;
}
.input-range__slider {
    background: #d39340;
    border: 1px solid #d39340;
    border-radius: 100%;
}
.input-range__track--active {
    background: #d39340;
}
.page-item .page-link.active {
    color: white;
    background-color: #f7a44d;
}