.success_img {
    text-align: center;
    margin-top: 50px;
}
.success_title {
    text-align: center;
}
.success_order_info {
    color: #262555;
    text-align: center;
    font-size: 30px;
}
.success_button {
    display: flex;
    justify-content: space-evenly;
}