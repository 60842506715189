.product_gallery_item {
    padding-right: 15px;
}
.products_gallery_main_title {
    margin-top: 50px;
}
.products_gallery_main_title h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 24px;
    color: #262555;
    border-bottom: 2px solid #262555;
    max-width: 80%;
    padding-bottom: 35px;
    margin-bottom: 50px;
}
.product_gallery_container {
    background-color: white;
    padding: 15px;
    margin-bottom: 20px;
}
/*.product_gallery_options p:first-child {*/
/*    color: rgba(0, 0, 0, 0.47);*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    font-size: 11px;*/
/*    line-height: 24px;*/
/*    margin-bottom: 0px;*/
/*}*/
.product_gallery_name {
    /*min-height: 96px;*/
    min-height: 72px;
    /*min-height: 48px;*/
}
.product_gallery_name a {
    color: #262555;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    overflow-wrap: break-word;
}
.product_gallery_image {
    height: 250px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}
.product_gallery_image img {
    width: 100%;
    height: auto;
}
.product_gallery_options {
    margin-top: 10px;
}
.product_gallery_options p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #262555;
    margin-bottom: 0;
}
.product_gallery_button  button:hover {
    color: white;
}
.product_gallery_button  button {
    width: 100%;
    border: none;
    background: #D39340;
    box-shadow: 0px 4px 4px rgba(51, 50, 105, 0.3);
    padding: 5px 0px;
    color: #262555;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.product_gallery_button {
    padding: 15px 0px;
}
.product_gallery_price {
    padding-top: 15px;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    color: #262555;
}
.product_button_in_cart {
    width: 100%;
    display: block;
    text-align: center;
    color: #D39340;
    min-height: 34px;
    padding-top: 5px;
}
.product_gallery_options {
    min-height: 69px;
}
.product_gallery_container .ribbon-wrapper {
    right: 15px!important;
    top: 0px!important;
}
/* Акція */
.product_gallery_discount_price_container {
    display: flex;
    padding-top: 13px;
}
.product_gallery_price_discount {
    color: #D39340;
    font-weight: 600;
    padding-left: 9%;
}
.product_gallery_price_discount_old_price {
    /*font-size: 14px;*/
    color: grey;
    position: relative;
    margin-left: 4%;
}
.product_gallery_price_discount_old_price::after {
    content: "";
    position: absolute;
    width: 119%;
    height: 2px;
    background-color: red;
    display: block;
    transform: rotate(-18deg);
    top: 10px;
    right: -10px;
    opacity: 0.4;
}
.product_gallery_discount_price_one_container {
    display: flex;
}
.product_gallery_discount_price_one_in_container {
    width: 55%;
    overflow: hidden;
    display: flex;
}
.product_gallery_discount_price_one_container .product_gallery_price_discount {
    width: 100%;
    font-size: 14px;
    padding-left: 0px;
}
.product_gallery_discount_price_one_container .product_gallery_price_discount_old_price::after {
    content: "";
    position: absolute;
    width: 114%;
    height: 2px;
    background-color: red;
    display: block;
    transform: rotate(-21deg);
    top: 5px;
    right: -7px;
    opacity: 0.4;
}
.product_gallery_discount_price_one_container .product_gallery_price_discount_old_price {
    width: 100%;
    font-size: 12px;
}
.product_gallery_discount_percent_container {
    position: absolute;
    display: block;
    top: 0px;
    background-color: #d39340;
    padding: 3px;
    color: white;
    left: 8px;
}
.product_gallery_price_one_static {
    padding-left: 2%;
}