.custom_button:hover {
    color: white;
}
.custom_button {
    width: 100%;
    border: none;
    background: #D39340;
    box-shadow: 0px 4px 4px rgb(51 50 105 / 30%);
    padding: 5px 0px;
    color: #262555;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.login-logo {
    font-weight: 500;
    font-size: 45px;
    color: #3b346d;
}