.pg-container {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: stretch;
}

.pg-item {
    border-radius: 15px;
    border: 1px solid lightgrey;
    padding: 15px;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    height: 90px;
    width: 79%;
    cursor: pointer;
}

.pg-item:first-child {
    margin-top: 15px;
}

.pg-item > img {
    object-fit: contain;
    max-width: 100%;
}

.pg-item.active {
    border-color: #D39340;
    box-shadow: 0 0 6px #D39340;
}
