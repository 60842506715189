.partner_programm_title {
    border-bottom: 2px solid #262555;
    max-width: 80%;
    padding-bottom: 25px;
    margin-bottom: 25px;
    color: #262555;
}
.partner_programm_sub_title {
    border-bottom: 2px solid #262555;
    max-width: 80%;
    padding-bottom: 15px;
    margin-bottom: 20px;
    margin-top: 20px;
    color: #262555;
}
.partner_alert {
    text-align: center;
}