footer {
    background-color: #E1E1E1;
    padding: 20px 0px;
}
.footer_logo img {
    max-width: 160px;
}
.footer_image_block img {
    margin-right: 10px;
    /*margin-left: 5px;*/
    max-width: 21px;
}
.footer_corporate {
    color: #262555;
    font-size: 18px;
    font-weight: 500;
}
.footer_menu {
    display: flex;
    flex-flow: column;
}
.footer_menu a {
    color: black;
    font-weight: 600;
}
.footer_contacts {
    display: flex;
    flex-flow: column;
    color: black;
    font-weight: 600;
}
.footer_menu a:hover {
    color: #D39340;
}
.footer_contacts a:hover {
    color: #D39340;
}
.footer_contacts a {
    color: black;
}
.cookie_container {
    background-color: #262555;
    padding: 20px 0px;
}
.cookie_form {
    width: 100%;
    align-items: center;
    text-align: center;
}
.cookie_form button:first-child {
    margin-right: 20px;
}
.cookie_form button:hover {
    color: white!important;
}
.cookie_form button {
    background-color: #D39340;
    color: #262555;
    width: 150px;
    padding: 5px;
    border: none;
}
.cookie_text_info {
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: white;
}
.footer_seo_title h1 {
    text-align: left;
    color: #262555;
    font-size: 16px;
    margin-bottom: 0;
    padding-top: 2px;
}
.seo_market_style a {
    text-decoration:none;
    font-size:inherit;
    color:inherit;
}
.seo_market_style a:hover {
    color: #D39340;
}
.seo_market_style span {
    font-weight: 600;
}