.tags_main_title h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 24px;
    color: #262555;
    padding-bottom: 35px;
    border-bottom: 1px solid #262555;
    margin-bottom: 30px;
    width: 80%;
}
.tags_main_title {
    width: 100%;
}
.tags_main_container {
    margin-bottom: 105px;
    margin-top: 50px;
}
.tags_item {
    /*width: 197px;*/
    padding: 0px 10px;
    height: 51px;
    background: #FFFFFF;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.12);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #262555;
    margin-right: 19px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 19px;
    cursor: pointer ;
}
.tags_main_container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}
.tags_item:hover {
    background-color: #D39340;
    color: white;
}