.checkout .card-header h4 {
    color: #262555;
}
.checkout_button button {
    background-color: #D39340;
    border: none;
    padding: 8px;
    color: #262555;
}
.checkout_button button:hover {
    color: white;
}
.icheck-primary {
    color: #262555;
}
.payments input {
    height: 17px;
    flex: initial!important;
    width: 50px!important;
}
.payments label {
    margin-bottom: 0px;
}
.card-body.row.payments div {
    display: flex;
    align-items: center;
}
.form-select-container {
    width: 100%;
}
#way-for-pay-form {
    display: none;
}
.checkout_sub_title {
    padding-top: 0px;
    padding-bottom: 0px;
    color: #262555;
}
.checkout_link_color {
    color: #D39340;
    font-weight: 700;
}
.checkout-header-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}
.checkout-franchisees-label-container {
    color: #D39340;
}