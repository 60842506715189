.loader_container_main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    z-index: 9999;
}
.loader_container_main svg {
    width: 100px;
    height: 100px;
}
.loader_message_container {
    color: #050038;
    font-size: 30px;
    text-align: center;
}