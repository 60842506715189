.registration form {
    width: 100%;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center
}
.registration-title {
    border-bottom: 2px solid #262555;
    max-width: 80%;
    margin-bottom: 50px;
}
.registration .form-group {
    display: flex;
    align-items: center;
}
.registration .form-group label {
    min-width: 15%;
    color: #262555;
}
.registration_sub_title {
    border-bottom: 2px solid #262555;
    max-width: 30%;
    margin-bottom: 30px;
    color: #262555;
}
.registration .form-group.registration_news label {
    min-width: 18%;
    margin-bottom: 0px;
}
.registration .form-group.registration_news div input {
    width: 17px;
    margin-right: 5px;
}
.registration .form-group.registration_news div {
    display: flex;
    width: 8%;
    align-items: center;
}
.registration .form-group.registration_news {
    align-items: center;
}
.registration .form-group.registration_news p {
    margin-bottom: 0px;
}
.registration_button button:hover {
    color: white!important;
    background-color: #D39340;
}
.registration_button button {
    background-color: #D39340;
    color: #262555;
    border: none;
    font-size: 20px;
    font-weight: 500;
}
.registration_button {
    text-align: center;
    align-items: center;
    justify-content: center;
}
.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #d39340;
    background-color: #d39340;
    box-shadow: none;
}
.registration_add_company_data {
    width: 54%!important;
}
.register_form {
    display: block;
}