.home_main_slider_section {
    margin-top: 50px;
}
.css-18124cf {
    height: 9px!important;
    width: 9px!important;
}
.css-15grxc6 {
    height: 9px!important;
    width: 9px!important;
    background-color: #D39340!important;
}
.add_style {
    padding-right: 8.5px;
    padding-left: 8.5px;
}