.breadcrumb {
    background: none;
    padding: 0;
    margin: 20px 0;
}
.breadcrumb-item+.breadcrumb-item::before {
    content: '>';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #262555;
}
.breadcrumb-item > a {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #262555;
}
.breadcrumb-item.active {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
}
.breadcrumb_container_with_status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
}
.breadcrumb_status_partner {
    color: #f7a44d;
}