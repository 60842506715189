.account_user_photo {
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.account_user_photo img {
    max-width: 100%;
}
.account_menu_container .row {
    justify-content: center;
    align-items: center;
    flex-flow: row;
}
.account_user_name {
    color: #262555;
    font-size: 18px;
}
.account_menu_container {
    border-right: 1px solid rgba(38, 37, 85, 0.3);
    margin-bottom: 20px;
}
.account_partner_button {
    padding: 0px 10px;
    margin: 15px 0px;
}
/*Старі стилі кнопки "Стати партнером"*/
/*.account_partner_button button {*/
/*    width: 100%;*/
/*    padding: 15px;*/
/*    background-color: #f7a44d;*/
/*    border: none;*/
/*    border-radius: 5px;*/
/*    color: white;*/
/*    box-shadow: 0 3px 5px lightgrey;*/
/*}*/
.account_partner_button button {
    text-decoration: none;
    outline: none;
    display: inline-block;
    background-image: linear-gradient(
            45deg
            , #e39940 0%, #ffd19d 50%, #ff8b00 100%);
    background-position: 100% 0;
    background-size: 200% 200%;
    color: white;
    border-radius: 10px;
    padding: 12px 0px;
    /*margin: 10px 20px;*/
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 300;
    /*box-shadow: 0 16px 32px 0 rgb(0 40 120 / 35%);*/
    transition: .5s;
    border:none;
    width: 100%;
}
.account_partner_button button:hover {
    box-shadow: 0 0px 0px 0 rgb(0 40 120 / 0%);
    background-position: 0 0;
}
.account_menu_title {
    /*background-color: #f7a44d;*/
    background-color: #403c75;
    padding: 15px 0px;
    color: white;
    display: flex;
    align-items: center;
}
.account_menu_title .account_user_name {
    color: white;
}
.account_menu_items {
    display: flex;
    flex-flow: column;
    padding: 0px;
    background-color: white;

}
.account_menu_items a {
    color: #262555;
    padding: 10px 10px 10px 25px;
}
.account_menu_items a:hover {
    /*background-color: #f7a44d;*/
    color: #f7a44d;
}
.account_menu_block {
    margin: 15px 0px;
}
.account_title {
    color: #262555;
    font-size: 40px;
    font-weight: 500;
    padding-left: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(38, 37, 85, 0.3);
    width: 100%;
}
.account_content_container {
    padding-left: 0px;
}
.account_pointer {
    cursor: pointer;
}
.profile_item_container {
    align-items: center;
}
.account_table_head {
    background: #f7a44d;
    color: white;
}
.account_user_exit i {
    color: #262555!important;
    background: white;
    padding: 15px;
    border-radius: 50px;
}
.account_user_exit_container {
    margin-top: 10px;
}
.account_item_menu_active {
    background-color: #f7a44d;
    color: white!important;
}
.account_person_info {
    margin-top: 10px;
}
.partner_add_product_sale_container {
    display: flex;
    padding-bottom: 15px;
}
.partner_add_product_sale_container .form-select-container {
    max-width: 50%;
    margin-right: 10px;
}
.partner_sale_table {
    margin-bottom: 0px;
}
.count_partner_sale_td {
    display: flex;
    flex-flow: column;
    align-items: center;
}
.count_partner_sale_td input {
    width: 100px;
}
.partner_sale_table td {
    vertical-align: inherit!important;
}
.customer_order_address {
    width: 100%;
    margin-top: 10px;
}
.account_order_invoice_link img {
    margin-left: 10px;
}
.account_order_invoice_link {
    background-color: #f7a44d;
    color: white!important;
    padding: 10px;
}
.pagination {
    padding-left: 0;
}
.page-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}
.page-item .page-link {
    color: black;
}
.page-item .page-link.active {
    color: white;
    background-color: #f7a44d;
}
.partner_bonus_form {
    display: flex;
}
.partner_bonus_form button {
    margin-left: 10px;
}
.partner_bonus_form .form-group {
    margin-bottom: 0px!important;
}
.profile_personal_data {
    float: right;
    cursor: pointer;
    color: #262555!important;
}
.profile_edit_container {
    display: flex;
    flex-flow: column;
    align-items: unset;
}
.profile_item_container span:first-child {
    margin-right: 5px;
}