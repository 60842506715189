.confirm_title {
    width: 100%;
    padding-top: 30px;
}
.confirm_title h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 24px;
    color: #262555;
    border-bottom: 2px solid #262555;
    max-width: 60%;
    padding-bottom: 35px;
    margin-bottom: 50px;
}
.confirm_title_type h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    color: #262555;
    border-bottom: 1px solid lightgrey;
    max-width: 20%;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.confirm_info_product {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}
.confirm_count_product {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 60%;
}
.confirm_container button {
    background-color: transparent;
    border: none;
}
.confirm_count_product input {
    max-width: 50px;
}
.confirm_price_product {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: #262555;
}
.confirm_delete_product {
    display: flex;
    justify-content: flex-end;
}
.confirm_container {
    background-color: white;
    padding: 20px;
    margin-bottom: 10px;
}
.confirm_name_product {
    color: #262555;
    align-items: center;
    display: flex;
}
.confirm_total_container {
    justify-content: flex-end;
}
.confirm_total_block {
    background-color: white;
    padding: 15px;
    width: 100%;
}
.confirm_discount_type {
    border-bottom: 1px solid lightgrey;
    padding: 10px;
    margin-bottom: 5px;
    align-items: center;
}
.confirm_discount_type div:nth-child(1) span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgba(38, 37, 85, 0.5);
}
.confirm_discount_type div:nth-child(2) p {
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 22px;
    align-items: baseline;
    display: flex;
    justify-content: center;
    color: #262555;

}
.confirm_discount_type div:nth-child(2) p span {
    color: rgba(38, 37, 85, 0.5);
    font-size: 14px;
    padding-left: 5px;
}
.confirm_final_price p {
    margin-bottom: 0px;
    padding: 10px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #262555;

}
.confirm_final_price div:last-child  {
    align-items: center;
    justify-content: center;
    display: flex;
}
.confirm_final_price div:last-child span {
    color: #D39340;
    font-weight: bold;
    font-size: 20px;

}
.confirm_button_payment button {
    background: #D39340;
    box-shadow: 0px 4px 4px rgba(38, 37, 85, 0.1);
    color: #262555;
    width: 100%;
    margin: 10px 0px;
    padding: 15px;
    border: none;
}