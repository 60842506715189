.modal.fade.show {
    display: block!important;
}
.modal.fade.show:after {
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(196, 196, 196, 0.5);
    z-index: 1;
}
.modal.fade.show .modal-dialog {
    z-index: 4;
}
.cart_container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid;
    padding: 10px;
    width: 100%;
}
.cart_container:last-child {
    border-bottom: none;
}
.cart_container button {
    background-color: transparent;
    border: none;
}
.cart_info_product {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}
.cart_count_product {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 60%;
}
.cart_count_product input {
    max-width: 50px;
    text-align: center;
}
.cart_name_product {
    font-size: 16px;
    color: #262555;
}
.cart_title_type {
    padding-left: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #262555;
    width: 28%;
    font-weight: bold;
}
.cart_price_product {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
}
@media (min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 1050px;
    }
}
.cart_title_type.row {
    color: #262555;
}
.cart_price_product {
    color: #262555;
}
.cart_total_sum {
    text-align: center;
    color: #d39340;
    padding-top: 20px;
    font-weight: 600;
    font-size: 18px;

}
.cart_delete_product {
    display: flex;
    justify-content: flex-end;
}
.cart_modal_footer_button a {
    background: #D39340;
    box-shadow: 0px 4px 4px rgba(38, 37, 85, 0.1);
    border: none;
    color: #262555;
    padding: 10px;
}
.cart_modal_footer_button a:hover {
    color: #FFFFFF;
}
.cart_modal_footer_button a {
    cursor: pointer!important;
}
.custom_overflow {
    max-height: 59vh;
    overflow-y: scroll;
}
.custom_overflow_hidden {
    overflow: hidden;
}
.card_title_card_empty {
    text-align: center;
    width: 100%;
    font-size: 18px;
    color: #262555;
}
.cart_info_price_change {
    text-align: right;
    font-size: 14px;
    color: grey;
    width: 100%;
}
.cart_amount_for_preorder {
    margin: 0px;
}