.info_page_text_center {
    text-align: center;
    font-size: 20px;
    padding: 10px 0px;
}
.info_page_about_text_info_company {
    min-width: 142px;
}
.info_page_about_text_info_container {
    display: flex;
}