.image_404 img {
    max-width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
}
.image_404 {
    display: flex;
    align-items: center;
    justify-content: center;
}
.text_404 {
    text-align: center;
    color: #262555;
    font-size: 40px;
}
.button_404 {
    display: flex;
    justify-content: center;
    padding: 20px 0px;
}
.button_404 a:hover {
    color: white!important;
}
.button_404 a {
    background: #D39340;
    box-shadow: 0px 4px 4px rgb(38 37 85 / 10%);
    border: none;
    color: #262555;
    padding: 10px;
}